<template>
  <div>
    <v-row v-if="loading" align="center" class="pa-4" justify="center">
      <v-progress-circular indeterminate size="25" color="primary" />
    </v-row>
    <div v-else>
      <h1 v-if="!bc" class="text-center mt-6">
        {{ $t("no_bc") }}
      </h1>
      <div v-else class="my-4">
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <h3>{{ $t("status") }}</h3>
            <h2>
              {{
                bc.Status == bc_status_enum.Processing
                  ? $t("processing")
                  : bc.Status == bc_status_enum.Analysing
                  ? $t("under_analysis")
                  : $t("concluded")
              }}
            </h2>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <h3>{{ $t("last_update") }}</h3>
            <h2>
              {{ gs.convert_date(bc.LastUpdate, true) }}
            </h2>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <h3>{{ $t("result") }}</h3>
            <h2>
              {{
                bc.Result == bc_result_enum.NOT_REVIEWED
                  ? $t("not_reviewed")
                  : bc.Result == bc_result_enum.REVIEW
                  ? $t("review")
                  : bc.Result == bc_result_enum.INVALID
                  ? $t("invalid")
                  : $t("valid")
              }}
            </h2>
          </v-col>
        </v-row>
      </div>
      <v-row class="my-2" justify="center">
        <v-btn
          :loading="requestLoading"
          @click="request_bc"
          rounded
          dense
          color="primary"
        >
          {{ $t("request_bc") }}
        </v-btn>
        <v-btn
          :loading="updateLoading"
          @click="bc_status_update"
          rounded
          dense
          color="primary"
        >
          {{ $t("bc_update") }}
        </v-btn>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.agency-acc {
  color: grey;
  font-size: 13px;
  margin-bottom: 0px;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { BackGroundCheckResultEnum } from "@/shared/enums/BackGroundCheckResultEnum";
import { BackgroundCheckStatusEnum } from "@/shared/enums/BackgroundCheckStatusEnum";
export default {
  name: "ManageBackgroundCheck",
  components: {},

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    updateLoading: false,
    requestLoading: false,
    bc_result_enum: BackGroundCheckResultEnum,
    bc_status_enum: BackgroundCheckStatusEnum,
    bc: null,
  }),

  computed: {},
  props: {
    User: Object,
  },

  async created() {
    this.loading = true;
    await this.bc_status_update();
    this.loading = false;
  },

  methods: {
    async bc_status_update() {
      this.updateLoading = true;
      await this.apiService
        .getRequest(`backgroundcheck/status/${this.User.Id}`)
        .then((resp) => {
          this.bc = JSON.parse(resp.message);
        })
        .catch((error) => {});
      this.updateLoading = false;
    },
    async request_bc() {
      this.requestLoading = true;
      await this.apiService
        .postRequest(`backgroundcheck/${this.User.Id}`)
        .then((resp) => {
          this.bc = JSON.parse(resp.message);
        })
        .catch((error) => {});
      this.requestLoading = false;
    },
  },
};
</script>
